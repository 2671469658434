import React, { lazy } from 'react';
import { YLoader } from '@ywfe/ywd';
// import { configs } from '@generated/config';

const LiveData = lazy(() => import('../pages/LiveData/index'));

const DashboardList = lazy(() => import('../pages/Dashboard/DashboardList'));
const InternalVersion = lazy(() => import('../pages/Dashboard/InternalVersion'));
const ExternalVersion = lazy(() => import('../pages/Dashboard/ExternalVersion'));

const AnchorOverview = lazy(() => import('../pages/AnchorData/AnchorOverview'));
const AnchorList = lazy(() => import('../pages/AnchorData/AnchorList'));
// const AnchorTopList = lazy(() => import('../pages/AnchorData/AnchorTopList'));
const AnchorDetail = lazy(() => import('../pages/AnchorData/AnchorDetail'));
const AnchorLiveDetail = lazy(() => import('../pages/AnchorData/AnchorLiveDetail'));
// const RankingDetail = lazy(() => import('../pages/AnchorData/RankingDetail'));
// const AnchorStatistics = lazy(() => import('../pages/AnchorData/AnchorStatistics'));
// const LiveWechat = lazy(() => import('../pages/AnchorData/LiveWechat'));

// const LiveGoods = lazy(() => import('../pages/GoodsData/LiveGoods'));
const LiveEvaluate = lazy(() => import('../pages/GoodsData/LiveEvaluate'));
// const LiveCompare = lazy(() => import('../pages/GoodsData/LiveCompare'));
const ExportList = lazy(() => import('../pages/GoodsData/ExportList'));

const LiveStreaming = lazy(() => import('../pages/LiveDataList/LiveStreaming'));
// const LiveList = lazy(() => import('../pages/LiveDataList/LiveList'));

// const LiveCustomer = lazy(() => import('../pages/DataMonitoring/LiveCustomer'));

// const token = localStorage.getItem('token');
// const projectId = 'PRO_DIANHU';
// const env = process.env.REACT_APP_ENV === 'prod' ? 'prod' : localStorage.getItem('env') || 'dev';
// const baseUrl = configs[projectId][env].APPLICATION_URL;

export const routerConfig = {
  // 首页
  liveData: {
    icon: 'HomeOutlined',
    component: () => (
      <YLoader>
        <LiveData />
      </YLoader>
    ),
  },
  /**
  // 排行榜详情
  rankingDetail: {
    hidden: true,
    component: () => (
      <YLoader>
        <RankingDetail />
      </YLoader>
    ),
  },
  */
  // 实时直播大屏
  dashboardList: {
    icon: 'VideoCameraOutlined',
    component: (props: any) => (
      <YLoader>
        <DashboardList {...props} />
      </YLoader>
    ),
  },
  // 直播大屏内部版
  dashboard: {
    hidden: true,
    component: () => (
      <YLoader>
        <InternalVersion />
      </YLoader>
    ),
  },
  // 直播大屏外部版
  liveScreen: {
    hidden: true,
    component: (props: any) => (
      <YLoader>
        <ExternalVersion {...props} />
      </YLoader>
    ),
  },
  // 直播数据
  anchorData: {
    icon: 'StarOutlined',
  },
  // 主播概况
  'anchorData/index': {
    component: () => (
      <YLoader>
        <AnchorOverview />
      </YLoader>
    ),
  },
  // 主播列表
  'anchorData/list': {
    component: () => (
      <YLoader>
        <AnchorList />
      </YLoader>
    ),
  },
  // 主播榜单
  // 'anchorData/anchorTopList': {
  //   component: () => (
  //     <YLoader>
  //       <AnchorTopList />
  //     </YLoader>
  //   ),
  // },
  // 主播详情
  'anchorData/detail': {
    hidden: true,
    component: () => (
      <YLoader>
        <AnchorDetail />
      </YLoader>
    ),
  },
  // 直播详情
  'anchorData/liveDetail': {
    hidden: true,
    component: () => (
      <YLoader>
        <AnchorLiveDetail />
      </YLoader>
    ),
  },
  /**
  // 快手主播统计
  'anchorData/AnchorStatistics': {
    component: () => (
      <YLoader>
        <AnchorStatistics />
      </YLoader>
    ),
  },
  */
  /**
  // 微信导流
  'anchorData/liveWechat': {
    component: () => (
      <YLoader>
        <LiveWechat />
      </YLoader>
    ),
  },
  */
  // 商品数据
  goodsData: {
    icon: 'ShoppingCartOutlined',
  },
  /**
  // 带货商品
  'goodsData/liveGoods': {
    component: () => (
      <YLoader>
        <LiveGoods />
      </YLoader>
    ),
  },
  */
  // 商品评价
  'goodsData/liveEvaluate': {
    component: () => (
      <YLoader>
        <LiveEvaluate />
      </YLoader>
    ),
  },
  /**
  // 全网比价
  'goodsData/liveCompare': {
    component: () => (
      <YLoader>
        <LiveCompare />
      </YLoader>
    ),
  },
  */
  // 导出列表
  'goodsData/exportList': {
    hidden: true,
    component: () => (
      <YLoader>
        <ExportList />
      </YLoader>
    ),
  },
  // 直播计划
  liveDataList: {
    icon: 'AreaChartOutlined',
  },
  // 直播计划
  'liveDataList/liveStreaming': {
    component: () => (
      <YLoader>
        <LiveStreaming />
      </YLoader>
    ),
  },
  /**
  // 直播日报
  'liveDataList/liveList': {
    component: () => (
      <YLoader>
        <LiveList />
      </YLoader>
    ),
  },
  */
  /**
  // 数据监控
  dataMonitoring: {
    icon: 'LineChartOutlined',
  },
  // 客服回复
  'dataMonitoring/liveCustomer': {
    component: () => (
      <YLoader>
        <LiveCustomer />
      </YLoader>
    ),
  },
   */
};
