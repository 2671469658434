import React from 'react';
import { YApplication } from '@ywfe/ywd';
import { routerConfig } from './router';

const App: React.FC = () => (
  <div className="dianhu">
    <YApplication
      config={{
        routerConfig,
        primaryDefaultMenu: 'liveData',
        secondaryDefaultMenu: '',
        projectId: 'PRO_DIANHU',
      }}
    />
  </div>
);

export default App;
