
            export const configs: Record<string, any> = {
  "PRO_DIANHU": {
    "mock": {
      "API_BASE_URL": "http://yapi.ywfe.com/mock",
      "APPLICATION_URL": ""
    },
    "dev": {
      "API_BASE_URL": "https://dev-gateway.ywwl.com",
      "APPLICATION_URL": ""
    },
    "test": {
      "API_BASE_URL": "https://test-gateway.ywwl.com",
      "APPLICATION_URL": "https://dianhu-test.ywwl.com/"
    },
    "test2": {
      "API_BASE_URL": "https://test2-gateway.ywwl.com",
      "APPLICATION_URL": "https://dianhu-test2.ywwl.com/"
    },
    "pre": {
      "API_BASE_URL": "https://gateway.ywwl.com",
      "APPLICATION_URL": ""
    },
    "prod": {
      "API_BASE_URL": "https://gateway.ywwl.com",
      "APPLICATION_URL": ""
    }
  },
  "AUTH": {
    "mock": {
      "API_BASE_URL": "http://yapi.ywfe.com/mock",
      "APPLICATION_URL": ""
    },
    "dev": {
      "API_BASE_URL": "https://dev-gateway.ywwl.com",
      "APPLICATION_URL": "http://pl.fed.ywwl.com"
    },
    "test": {
      "API_BASE_URL": "https://test-gateway.ywwl.com",
      "APPLICATION_URL": "http://framework.test.ywwl.com"
    },
    "test2": {
      "API_BASE_URL": "https://test2-gateway.ywwl.com",
      "APPLICATION_URL": "https://dianhu-test2.ywwl.com"
    },
    "pre": {
      "API_BASE_URL": "https://gateway.ywwl.com",
      "APPLICATION_URL": "https://yun.ywwl.com"
    },
    "prod": {
      "API_BASE_URL": "https://gateway.ywwl.com",
      "APPLICATION_URL": "https://yun.ywwl.com"
    }
  }
}
        