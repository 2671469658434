import { configs } from '@generated/config';
// 获取地址参数
export const getQuery = (name: string, search?: string): string => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const values = reg.exec((search || window.location.search).substr(1));
  if (values != null) {
    return unescape(values[2]) || '';
  }
  return '';
};
// 拼接地址参数
export const setQuery = (obj: { [name: string]: string | number | boolean }): string => {
  let query = '';
  Object.keys(obj).forEach((key: string) => {
    query += `&${key}=${obj[key].toString()}`;
  });

  return query;
};
// 获取当前环境下接口地址
export const getBasePath = (): string => {
  const projectId = 'PRO_DIANHU';
  let env: string | null = null;
  if (typeof window !== 'undefined') {
    env = window.localStorage.getItem('env');
  }
  env = env || (process.env.REACT_APP_ENV === 'prod' ? 'prod' : process.env.REACT_APP_ENV) || 'dev';
  return `${(
    (configs as {
      [name: string]: {
        [name: string]: {
          [name: string]: string
        }
      }
    })[projectId] as {
      [name: string]: {
        [name: string]: string
      }
    })[env]?.API_BASE_URL}${env === 'mock' ? '/217' : ''}`;
};
// 获取LocalStorage中的值
export const getLocalStorage = (name: string): string => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(name) as any;
  }
  return getQuery(name) || '';
};
